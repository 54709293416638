<template>
  <div id="questionPaperPreview" class="" style="background-color: white;">
    <!-- header ----------------- -->
    <div class="customHeaderDiv mt-2" v-html="customHeader"></div>
    <!-- ------------------------------- -->
    <!-- questions --------------------- -->
    <b-row  style="font-family: 'Times New Roman', Times, serif;font-size: 16px;" :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }" class="ml-0 mr-0 table-header">
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">Si.No.</b-col>
      <b-col 
        :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">Question</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">Mark</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">CO</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">ML</b-col>
      <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">BL</b-col>
    </b-row>

    <div class="text-danger" v-if="loading"> Loading...</div>
    <div v-else v-for="(qORg, qgindex) in questionsAndGroupsAndSectionView" :key="qgindex" style="font-family: 'Times New Roman', Times, serif;">
      <div v-if="qORg.type == 'group'">
        <div :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" class="text-center text-info">
          Answer {{ groupObjects[qORg.id].best_of_questions }} out of {{ groupObjects[qORg.id].questions.length }}
          questions
        </div>
        <div v-for="(question, orQindex) in groupObjects[qORg.id].questions" :key="orQindex">
          <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }" class="ml-0 mr-0">
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
              question.question_no }}.</b-col>
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
              <span v-for="(qData, qdIndex) in question.evaluation_scheme" :key="qdIndex" class="mr-1">
                <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                <img class="questionPaperPreviewImg" v-if="qData.type == 'image'"
                  :src="questionAttachments[qData.value].url">
              </span>
            </b-col>

            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
              question.maximum_mark }}</b-col>
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
              <span v-if="(question.course_outcome.length > 0)">
                {{ question.course_outcome.map(item => item.code).join(', ') }}
              </span>
            </b-col>
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
              <span v-if="(question.module_id != null)">{{ moduleName(question.module_id) }}</span>
            </b-col>
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
              <span v-if="(question.blooms_taxonamy_level != null)">{{ question.blooms_taxonamy_level }}</span>
            </b-col>
          </b-row>

          <!-- Subquestions -->
          <div v-if="(question.subQuestions.length > 0)">
            <div v-for="(subQuestion, subQindex) in question.subQuestions" :key="subQindex">
              <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }" class="ml-0 mr-0">
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">{{
                  subQuestion.question_no }}.</b-col>
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
                  <span v-for="(qData, qdIndex) in subQuestion.evaluation_scheme" :key="qdIndex" class="mr-1">
                    <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                    <img class="questionPaperPreviewImg" v-if="qData.type == 'image'"
                      :src="questionAttachments[qData.value].url">
                  </span>
                </b-col>
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
                  subQuestion.maximum_mark }}</b-col>
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                  <span v-if="(subQuestion.course_outcome.length > 0)">
                    {{ subQuestion.course_outcome.map(item => item.code).join(', ') }}
                  </span>
                </b-col>
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                  <span v-if="(subQuestion.module_id != null)">{{ moduleName(subQuestion.module_id) }}</span>
                </b-col>
                <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                  <span v-if="(subQuestion.blooms_taxonamy_level != null)">{{ subQuestion.blooms_taxonamy_level
                    }}</span>
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- Add OR as a row with merged borders -->
          <b-row v-if="(groupObjects[qORg.id].questions.length === 2 && orQindex === 0)"
            class="text-center font-weight-bold ml-0 mr-0"
            :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }">
            <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="12">OR</b-col>
          </b-row>
        </div>
      </div>

      <!-- Normal question -->
      <div v-if="qORg.type == 'question'">
        <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }" class="ml-0 mr-0">
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">{{
            questionObjects[qORg.id].question_no }}.</b-col>
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
            <span v-for="(qData, qdIndex) in questionObjects[qORg.id].evaluation_scheme" :key="qdIndex" class="mr-1">
              <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
              <img class="questionPaperPreviewImg" v-if="qData.type == 'image'"
                :src="questionAttachments[qData.value].url">
            </span>
          </b-col>
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
            {{ questionObjects[qORg.id].maximum_mark }}</b-col>
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
            <span v-if="(questionObjects[qORg.id].course_outcome.length > 0)">
              {{ questionObjects[qORg.id].course_outcome.map(item => item.code).join(', ') }}
            </span>
          </b-col>
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
            <span v-if="(questionObjects[qORg.id].module_id != null)">{{ moduleName(questionObjects[qORg.id].module_id)
              }}</span>
          </b-col>
          <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
            <span v-if="(questionObjects[qORg.id].blooms_taxonamy_level != null)">{{
              questionObjects[qORg.id].blooms_taxonamy_level }}</span>
          </b-col>
        </b-row>

        <!-- Subquestions -->
        <div v-if="(questionObjects[qORg.id].subQuestions.length > 0)">
          <div v-for="(subQuestion, subQindex) in questionObjects[qORg.id].subQuestions" :key="subQindex">
            <b-row :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }" class="ml-0 mr-0">
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">{{
                subQuestion.question_no }}.</b-col>
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }">
                <span v-for="(qData, qdIndex) in subQuestion.evaluation_scheme" :key="qdIndex" class="mr-1">
                  <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                  <img class="questionPaperPreviewImg" v-if="qData.type == 'image'"
                    :src="questionAttachments[qData.value].url">
                </span>
              </b-col>
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1"> {{
                subQuestion.maximum_mark }}</b-col>
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                <span v-if="(subQuestion.course_outcome.length > 0)">
                  {{ subQuestion.course_outcome.map(item => item.code).join(', ') }}
                </span>
              </b-col>
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                <span v-if="(subQuestion.module_id != null)">{{ moduleName(subQuestion.module_id) }}</span>
              </b-col>
              <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="1">
                <span v-if="(subQuestion.blooms_taxonamy_level != null)">{{ subQuestion.blooms_taxonamy_level }}</span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <!-- Add SECTION as a row with merged borders -->
      <b-row v-if="qORg.type == 'section'" class="text-center font-weight-bold ml-0 mr-0"
      
        :class="{ 'no-border': !examSettings.enableEvaluationSchemeTableBorder }">
        <b-col :class="{ 'border-cell': examSettings.enableEvaluationSchemeTableBorder }" cols="12">{{
          sectionObjects[qORg.id].name }}</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import * as MathLive from 'mathlive';
Vue.use(MathLive);

export default {
  props: ["questionsAndGroupsAndSectionView", "groupObjects", "exam", "questionObjects", "sectionObjects", "examSettings"],
  async created() {
    // console.log(this.questionObjects);
    // this.questionsAndGroupsAndSectionView=this.questionsAndGroupsAndSectionViewProps,
    // this.groupObjects=this.groupObjectsProps,
    // this.exam=this.examProps,
    // this.questionObjects=this.questionObjectsProps,
    // this.sectionObjects=this.sectionObjectsProps,
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;

    for (const key in this.questionObjects) {
      const q = this.questionObjects[key];
      q.files.forEach(file => {
        this.questionAttachments[file.id] = {
          id: file.id, name: file.file_name, url: this.cloudStorageRoot + file.file_path + file.file_name
        };
      });
    }

    this.loading = true;
    await Promise.all([
      this.getCourseModules(),
      this.ExamEvaluationSchemeCustomHeader(),
    ]);
    this.loading = false;
    await MathLive.renderMathInDocument();

    for (const key in this.questionObjects) {
      const q = this.questionObjects[key];
      q.course_outcome.forEach(co => {
        this.courseOutcomes[co.id] = co;
      });
    }
    // this.prepareMarkDistributionGraph();
  },
  components: {
  },
  data() {
    return {
      courseOutcomes: {},
      bloomTaxonomyOptions: [
        {
          value: "6",
          text: "Creating",
          description: "User information to create something new",
        },
        {
          value: "5",
          text: "Evaluating",
          description: "Examine information and make judgement.",
        },
        {
          value: "4",
          text: "Analyzing",
          description: "Take apart from known and identify relationships.",
        },
        {
          value: "3",
          text: "Applying",
          description: "Use information in a new (but similar) situation.",
        },
        {
          value: "2",
          text: "Understanding",
          description: "Grasp meaning of instructional materials.",
        },
        {
          value: "1",
          text: "Remembering",
          description: "Recall specific facts.",
        },
      ],
      // questionsAndGroupsAndSectionView:[],
      // groupObjects:[],
      // exam:{},
      // questionObjects:[],
      // sectionObjects:[],
      loading: false,
      cloudStorageRoot: '',
      courseModules: [],
      customHeader: null,
      // slot_number: '',
      // footerData: { course_admin: '', maingroup_admin: '' },

      coScoreDistribution: {},
      taxonamyScoreDistribution: {},
      questionAttachments: {},
    };
  },
  methods: {
    latexCodeToMathLive(codeText) {
      if (codeText == null || codeText == '') return '';
      return codeText.replace(/\$\$(.*?)\$\$/g, '<math-field class="p-0" style="border: none;" readonly>$1</math-field>');
    },
    // questionAttachmentDetails(attachmentId, question) {
    //   // console.log(question);
    //   return question.files.find(item => item.id == attachmentId);
    // },
    moduleName(id) {
      let module = this.courseModules.find(item => item.id === id);
      if (module) return module.module;
      return id;
    },
    async getCourseModules() {
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.exam.course_id);
      await this.$axios.get(url).then((response) => {
        this.courseModules = response.data;
      });
    },

    async ExamEvaluationSchemeCustomHeader() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/exams/${this.exam.id}/evaluationscheme/customheader`;
      await this.$axios.get(url).then((response) => {
        this.customHeader = response.data;
      });
    },
  },
};
</script>

<style>
.question-propertycol-10 {
  width: 10%;
  word-break: break-all;
  text-align: center;
}

.question-propertycol-20 {
  width: 20%;
  word-break: break-all;
  text-align: center;
}

.question-propertycol-30 {
  width: 30%;
  word-break: break-all;
  text-align: center;
}

.question-propertycol-40 {
  width: 40%;
  word-break: break-all;
  text-align: center;
}

.questions-container button {
  padding: 1px !important;
}

@media print {
  .no-print {
    display: none !important;
  }

  .tempInput {
    border: none;
  }

  .questionPaperPreview {
    font-size: 14pt !important;
  }

  * {
    font-size: 14pt !important;
  }

}

.questionPaperPreviewImg {
  max-width: 100%;
  max-height: 600px;
}

.customHeaderDiv th {
  font-size: 12pt;
  background-color: #d7dcda;
}

.customHeaderDiv .data-tbody {
  font-size: 11pt;
  /* text-align: left; */
}

/* .data-table{
            table-layout: fixed;
            width: 660pt;
        } */
.customHeaderDiv .text-center {
  text-align: center;
}

.customHeaderDiv .header-table {
  font-size: 11pt !important;
  text-align: center;
}

.customHeaderDiv .header-table strong {
  font-size: 17pt !important;
  font-weight: 800;
}

.customHeaderDiv table {
  /* text-align: left; */
  width: 100%;
  border-collapse: collapse;
}

.customHeaderDiv table,
th,
td {
  border: 1px solid #080808;
}

.customHeaderDiv td {
  padding-bottom: 2pt;
  padding-top: 4pt;
  padding-left: 4pt;
  padding-right: 4pt;
}

.customHeaderDiv th {
  padding-bottom: 4pt;
  padding-top: 7pt;
  padding-left: 7pt;
  padding-right: 7pt;
}

.customHeaderDiv table tr td {
  height: auto;
}

.customHeaderDiv .wordbreak {
  word-wrap: break-word;
}


.question-propertycol-10,
.question-propertycol-20,
.question-propertycol-30,
.question-propertycol-40 {
  border: 1px solid black;
  text-align: center;
}

.question-propertycol-10 {
  width: 10%;
}

.question-propertycol-20 {
  width: 20%;
}

.question-propertycol-30 {
  width: 30%;
}

.question-propertycol-40 {
  width: 40%;
}

/* Add border to every row and column in the question layout */
.b-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 5px;
}

.b-col {
  border-right: 1px solid black;
  padding: 5px;
}

.b-col:last-child {
  border-right: none;
  /* Remove the right border from the last column */
}

/* Table header styles */
.b-row:first-child {
  border-top: 1px solid black;
}

.b-row:last-child {
  border-bottom: 1px solid black;
}

.customHeaderDiv {
  border: 1px solid black;
}

.customHeaderDiv th,
.customHeaderDiv td {
  border: 1px solid black;
}

/* Print-specific adjustments */
@media print {
  .b-row {
    page-break-inside: avoid;
  }

  .no-print {
    display: none;
  }

  .tempInput {
    border: none;
  }

  .questionPaperPreview {
    font-size: 14pt;
  }

  * {
    font-size: 14pt;
  }

  .questionPaperPreviewImg {
    max-width: 100%;
    max-height: 600px;
  }
}
</style>

<style scoped>
.table-header {
  background-color: #ffffff;
  /* Light gray background for header */
  font-weight: bold;
}

.border-cell {
  border: 0.1px solid black; /* Transparent border for effect */
  box-shadow: 0 0 0 0.6px #f7f2f2; /* Create the appearance of a thin border */
  padding: 0px; /* Consistent padding */
}

.flex-container {
  display: flex;
  /* Flexbox for column layout */
}

/* Adjust widths for properties */
.question-propertycol-10,
.question-propertycol-20,
.question-propertycol-30,
.question-propertycol-40 {
  width: calc(25% - 2px);
  /* Adjust width as needed */
}

.questionPaperPreviewImg {
  max-width: 100%;
  /* Responsive image */
  height: auto;
  /* Maintain aspect ratio */
}

.customHeaderDiv {
  margin-bottom: 10px;
  /* Space below the header */
}

/* Optional: Reduce spacing between rows */
.b-row {
  padding: 3px 0;
  /* Vertical padding for row height */
}

@media print {
  .b-row {
    page-break-inside: avoid;
  }

  .no-print {
    display: none;
  }

  .tempInput {
    border: none;
  }

  .questionPaperPreview {
    font-size: 14pt;
  }

  * {
    font-size: 14pt;
  }

  .questionPaperPreviewImg {
    max-width: 100%;
    max-height: 600px;
  }

  /* Remove border from all rows */
  .b-row {
    display: flex;
    align-items: center;
    /* Remove the bottom border */
    /* border-bottom: 1px solid black; */
    padding: 5px;
  }

  /* Keep the border for header or first row only */
  .b-row:first-child {
    border-top: 1px solid rgb(0, 0, 0);
    /* If you want a border only for the header */
  }

  /* Adjust border-row style */
  .border-row {
    /* Remove bottom border from border-row */
    /* border-bottom: 1px solid #dee2e6; */
    /* Remove this line */
    margin: 0;
    /* Keep margins */
    padding: 0;
    /* Remove padding to eliminate space */
  }

  /* Keep the existing border for individual cells */
  .border-cell {
    border-right: 1px solid #000000;
    /* Light border for each cell */
    padding: 2px;
    /* Reduced padding for tighter spacing */
  }

  /* Remove right border from last column */
  .b-col:last-child {
    border-right: none;
    /* Remove the right border from the last column */
  }

  .no-border {
    border: none !important;
    /* Remove borders */
  }

  .border-cell {
    border: 1px solid #000000;
    /* Adjust the border style as needed */
  }

  .custom-border-div {
    border-style: solid;
    border-top-width: 5px;
    border-right-width: 10px;
    border-bottom-width: 15px;
    border-left-width: 20px;
    border-color: black;
  }

  #questionPaperPreview {
    font-family: "Times New Roman", serif;
  }

}
</style>
